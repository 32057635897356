/* Responsive Menu Here
----------------------------------------*/
ul {
  margin: 0px;
  padding: 0px;
}
.header-btn {
  background-color: #9b8afc;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}
.header-text-btn{
    color: #0d1e50;
    padding-right: 10px;
    font-weight: 600;
}
.react-menus {
  list-style: none;
  margin: 0;
  padding: 0;
}
.react-menus li {
  list-style: none;
}
.react-menus li > a {
  color: #c0c0c0;
}
.react-menus > li > a {
  display: block;
  position: relative;
  margin: 0;
  padding: 32px 34px 32px 34px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}
body.react-one-page .react-menus > li > a {
  padding: 26px 16px 26px 16px;
}

.react-menus li:hover > a,
.react-menus li.menu-active > a {
  color: #0d1e50;
}

.react-menus li ul.sub-menu li:hover > a,
.react-menus li ul.sub-menu li.menu-active > a {
  color: #0d1e50;
}

.react-menus li div.menu-active {
  position: relative;
}

.react-menus > li > ul.sub-menu {
  list-style: none;
  clear: both;
  margin: 0;
  position: absolute;
  z-index: 999;
  border-radius: 0 0 4px 4px;
}
.react-menus li ul.sub-menu {
  background: #fff;
  padding: 18px 0 18px;
  box-shadow: 30px 0 30px rgba(8, 10, 41, 0.1);
}
.react-menus li ul.sub-menu > li {
  width: 200px;
}
.react-menus li {
  position: relative;
}
.react-menus li ul.sub-menu li a {
  display: block;
  margin: 0px 0px;
  padding: 5px 35px 5px 35px;
  text-decoration: none;
  font-weight: normal;
  background: none;
  color: #0a0a0a;
}

.react-menus > li > ul.sub-menu > li ul.sub-menu > li ul.sub-menu {
  position: absolute;
  left: 100%;
  top: 0px;
  display: none;
  list-style: none;
}

.react-menus > li.edge > ul.sub-menu {
  right: 0;
}

.react-menus li ul.sub-menu li.edge ul.sub-menu,
.react-menus > li > ul.sub-menu > li.edge ul.sub-menu > li.edge ul.sub-menu {
  left: -100% !important;
}

/* Menu Toggle Btn
----------------------------------------*/

.menu-toggle .icon-bar {
  display: block !important;
  width: 25px;
  height: 2px;
  background-color: #fff !important;
  margin: 6px 0;
  transition: all 500ms ease;
}

.menu-toggle .icon-bar:nth-child(1) {
  width: 16px;
  margin-left: auto;
}

.menu-toggle .icon-bar:nth-child(2) {
  width: 20px;
  margin-left: auto;
}

.menu-toggle .icon-bar:hover {
  background-color: #fff !important;
}
.menu-toggle:hover span.icon-bar {
  width: 25px !important;
}
.menu-toggle #menu-btn {
  float: right;
  background: #202020;
  border: 1px solid #0c0c0c;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  clear: right;
  display: none;
}
.hide-menu {
  display: none;
}

/* Responsive Menu Styles
----------------------------------------*/
/*Note: change the max-width asper your requirment and change the same in aceResponsiveMenu({resizeWidth: "991" }) function*/

@media screen and (min-width: 992px) {
  .react-main-menu nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 991px) {
  .react-menus li ul.sub-menu li a {
    padding: 12px 40px 12px 50px;
  }
  .menu-toggle #menu-btn {
    display: block;
  }
  .react-menus .has-sub .arrow {
    width: 55px;
    height: 54px;
    display: block;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
    border-left: 1px solid #f5f3f3;
  }

  .react-menus .has-sub .hash .arrow:after {
    content: "";
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #0d1e50;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .react-menus .has-sub .hash.menu-active .arrow:after {
    content: "";
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #0d1e50;
    border-top: 0px solid #0d1e50;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ul[data-menu-style="vertical"],
  ul[data-menu-style="accordion"],
  ul[data-menu-style="vertical"] li ul.sub-menu {
    width: 100% !important;
  }
  .react-menus {
    position: absolute;
    width: 100%;
    box-shadow: none;
    margin-bottom: 12px;
  }
  .react-menus > li {
    border-bottom: 1px solid #f5f3f3;
    float: none;
  }
  .react-menus > li ul.sub-menu > li {
    border-top: 1px solid #f5f3f3;
    float: none;
  }

  .react-menus > li ul.sub-menu > li:last-child {
    border-bottom: none;
  }

  .react-menus li {
    position: relative;
    background: #fff;
  }
  .react-menus li a:hover {
    background: #f5f3f3 !important;
    color: #0d1e50 !important;
  }
  .react-menus li > .react-current-page {
    color: #3270fc !important;
  }
  .react-menus > li:first-child {
    border-top: none;
  }

  .react-menus li ul.sub-menu > li {
    width: 100%;
  }
  .react-menus li ul.sub-menu li ul.sub-menu li a {
    padding-left: 60px;
  }
  .react-menus li ul.sub-menu li ul.sub-menu li ul.sub-menu li a {
    padding-left: 80px;
  }
  .react-menus > li > ul.sub-menu {
    position: static;
  }
  .react-menus > li > ul.sub-menu > li ul.sub-menu {
    position: static;
  }
  .react-menus > li > ul.sub-menu > li ul.sub-menu > li ul.sub-menu {
    position: static;
  }
}
.react-sticky .topbar-area.style1 {
  display: none;
}
